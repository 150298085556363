
import Home from "/components/Home";

export default {
  name: 'App',
  components: {
    Home,
  },
  head() {
    return {
      meta: [
        { name: 'robots', content: 'index,follow' },
        { name: 'og:title', content: 'Mọt truyện - Gặm nhấm những câu truyện' },
        { name: 'og:description', content: 'Mọt Truyện – nơi hội tụ của những tâm hồn yêu thích văn chương và đam mê sáng tạo. Chúng tôi là nền tảng trực tuyến chuyên cung cấp những tác phẩm truyện chữ đa dạng, phong phú từ các tác giả tài năng. Tại đây, bạn có thể khám phá nhiều thể loại truyện, từ ngôn tình, xuyên không, kinh dị, đến phiêu lưu, huyền bí, mang đến những trải nghiệm đọc đầy cảm xúc. Không chỉ dừng lại ở việc đọc, Mọt Truyện còn tạo cơ hội cho các tác giả trẻ và tài năng chia sẻ tác phẩm của mình với cộng đồng. Hãy tham gia cùng chúng tôi, trải nghiệm những câu chuyện thú vị và kết nối với những người cùng chung đam mê. Đăng ký ngay hôm nay và bắt đầu hành trình khám phá thế giới truyện chữ đầy màu sắc!' },
        { name: 'og:image', content: '/og.webp' },
        { name: 'og:url', content: 'https://mottruyen.vn' },
        { name: 'keywords', content: 'truyen, đọc truyện , truyện convert , truyen convert, doc truyen , truyen hay, truyen vip' },
      ]
    }
  },
}
